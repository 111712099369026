<template>
  <div>
    <b-row v-if="orderMapId.length > 0">
      <b-col cols="12">
        <b-card class="my-card m-0">
          <b-table
            striped
            small
            id="myTables"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            :items="orderMapId"
            @row-clicked="clicked"
            :fields="fields"
            head-row-variant="secondary"
          >
            <template #cell(checkbox)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
              </b-form-checkbox>
            </template>
            <template #cell(create_user)="data">
              {{ data.item.create_user ? data.item.create_user.login : "" }}
            </template>
            <template #cell(to_addresses)="data">
              <span
                v-for="(to_address, idx) in data.item.to_addresses"
                :key="idx"
              >
                {{
                  to_address
                    ? idx === data.item.to_addresses.length - 1
                      ? to_address.name
                      : to_address.name + "; "
                    : ""
                }}
              </span>
            </template>
            <template #cell(performer_name)="data">
              {{
                data.item.performer == null
                  ? ""
                  : data.item.performer.last_name +
                    " " +
                    data.item.performer.first_name
              }}
            </template>
            <template #cell(performer_phone)="data">
              {{ data.item.performer ? data.item.performer.phone : "" }}
            </template>
            <template #cell(performer_login)="data">
              <span
                @dblclick="getLogin(data)"
                v-if="data.item.performer"
                class="reference-field"
              >
                {{ data.item.id ? data.item.performer.login : "" }}
              </span>
            </template>
            <template #cell(performer_transport)="data">
              {{
                data.item.performer == null ||
                data.item.performer.transport == null
                  ? ""
                  : data.item.performer.transport.model +
                    ` ${data.item.performer.transport.color}` +
                    ` ${data.item.performer.transport.car_number}`
              }}
            </template>
            <template #cell(distance)="data">
              {{
                data.item.distance != null
                  ? data.item.distance.toFixed(1) + "км"
                  : ""
              }}
            </template>
            <template #cell(allowances)="data">
              <span
                v-for="(allowance, index) in data.item.allowances"
                :key="allowance.id"
              >
                {{
                  data.item.allowances.length - 1 === index
                    ? allowance.name
                    : allowance.name + "; "
                }}
              </span>
            </template>
            <template #cell(client_status)="data">
              {{ data.item.client_status == 0 ? "Не оповещён" : "Оповещён" }}
            </template>
            <template #cell(report)="data">
              <span @dblclick="getAssigment(data)" class="reference-field">
                (***)
              </span>
            </template>
            <template #cell(history)="data">
              <span
                @dblclick="getPathData(data)"
                class="reference-field"
                ref="textDiv"
              >
                (***)
              </span>
            </template>
            <template #cell(history_call)="data">
              <span @dblclick="getHistoryCall(data)" class="reference-field">
                (***)
              </span>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
export default {
  props: ["orderMapId"],
  data() {
    return {
      fields: [
        { key: "checkbox", label: "", thStyle: { width: "30px" } },
        { key: "id", label: "Id", sortable: true, thStyle: { width: "40px" } },
        {
          key: "division",
          label: "Подразделение",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_login",
          label: "Логин водителя",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "from_address.name",
          label: "Откуда",
          sortable: true,
          thStyle: { width: "220px" },
        },
        {
          key: "to_addresses",
          label: "Куда",
          sortable: true,
          thStyle: { width: "220px" },
        },
        {
          key: "price",
          label: "Цена",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "bonus_price",
          label: "Безнал",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "phone",
          label: "Номер телефона",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "dop_phone",
          label: "Доп. номер",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "report",
          label: "Запросы",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "past_minute",
          label: "ВП",
          sortable: true,
          thStyle: { width: "40px" },
        },
        {
          key: "tariff",
          label: "Тариф",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "status",
          label: "Статус",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "created_at",
          label: "Время поступления",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "date_time",
          label: "На время",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "assignmentBy",
          label: "Назначил",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "stat_for_drivers",
          label: "Доп. инфо для водителя",
          sortable: true,
          thStyle: { width: "200px" },
        },
        {
          key: "info_for_operator",
          label: "Доп. инфо для оператора",
          sortable: true,
          thStyle: { width: "200px" },
        },
        {
          key: "performer_name",
          label: "Водитель",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_transport",
          label: "Автомобиль",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "create_user",
          label: "Создал",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_phone",
          label: "Номер водителя",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "client_status",
          label: "Статус клиента",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "type",
          label: "Тип",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "allowances",
          label: "Надбавки",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "distance",
          label: "Расстояние",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "history",
          label: "История изменения",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "history_call",
          label: "История звонков",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "commission_price",
          label: "Коммиссия",
          sortable: true,
          thStyle: { width: "150px" },
        },
      ],
    };
  },
  mounted() {
    resizeable();
  },
  methods: {
    clicked(item, index, event) {
      this.$store.state.filterPlus.tableIndex = index;
      cellSelect();
    },
    getLogin(data) {
      this.$router.push({
        name: "infoDriver",
        query: { filter: data.item.performer.login },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Водитель " + data.item.performer.login,
        `/infoDriver`,
        data.item.performer.login,
      ]);
    },
    getAssigment(data) {
      this.$store.commit("draggableTab/ADD_TAB", [
        "Запросы " + data.item.id,
        `/order/assigment`,
        data.item.id,
      ]);
      this.$router.push({
        name: "report/assigment",
        query: { filter: data.item.id },
      });
    },
    getPathData(data) {
      this.$store.commit("draggableTab/ADD_TAB", [
        "История " + data.item.id,
        `/order/order-history`,
        data.item.id,
      ]);
      this.$router.push({
        name: "orderHistory",
        query: { filter: data.item.id },
      });
    },
    getHistoryCall(data) {
      this.$store.commit("draggableTab/ADD_TAB", [
        "Звонки " + data.item.id,
        `/order/history-call`,
        data.item.id,
      ]);
      this.$router.push({
        name: "orderHistoryCall",
        query: { filter: data.item.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/table.scss";
.my-card .card-body {
  padding: 0px;
}
.my-card {
  overflow: auto;
  height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
</style>
